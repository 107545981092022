<template>
  <ClientOnly>
    <div
      v-scroll-lock="isActive"
      class="block-overlay-popup__outer"
      :class="{
        'is-active': isActive,
      }"
    >
      <div class="block-overlay-popup">
        <div class="block-overlay-popup__header">
          <AtomHeading
            html-tag="h3"
            font-size="h3"
            :text="data.title"
          />
          <button
            class="block-overlay-popup__overlay-close"
            type="button"
            @click="isActive = false"
          >
            <IonIcon
              class="block-modalbox-overlay__close-icon"
              icon-name="close"
            />
          </button>
        </div>
        <AtomRichtext :html="text" />
      </div>
    </div>
  </ClientOnly>
</template>

<script setup>
const props = defineProps({
    data: {
        type: Array,
        default: () => [],
    },
});

const isActive = ref(false);

const storyblokApi = useStoryblokApi();
const text = computed(() => storyblokApi?.richTextResolver.render(props.data.text));

setTimeout(() => {
    isActive.value = true;
}, props.data.appearAfter || 5000);
</script>

<style lang="scss" scoped>
.block-overlay-popup__outer {
    @include z-index('confirmationModal');
    position: fixed;
    top: 0;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    background: hsla(0,0%,100%,.3);
    visibility: hidden;

    &.is-active {
        visibility: visible;
    }
}
.block-overlay-popup {
    position: relative;
    display: flex;
    overflow: scroll;
    width: 960px;
    max-width: 960px;
    height: auto;
    max-height: 100%;
    flex-direction: column;
    padding: 90px;
    margin:  var(--grid-gutter)  var(--page-padding);
    margin-top: var(--grid-gutter);
    margin-bottom: var(--grid-gutter);
    background-color: var(--c-white);
    box-shadow: 0 0 28px 0 rgba(49, 49, 49, 0.36);

    @include remove-scrollbars;

    @include mobile {
        flex: 1;
        padding: 20px;
        border-radius: 0;
        margin-bottom: 0;
        box-shadow: none;
    }
}
.block-overlay-popup__header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 30px;
    gap: 20px;
}
.block-overlay-popup__overlay-close {
    margin-top: 5px;

}
</style>
